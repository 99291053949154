import {
  generateMultipleAntibioticsQuestion,
  generateMultipleBacteriaQuestion,
  generateRankingAntibioticQuestion,
  generateSingleAntibioticQuestion,
  generateSingleBacteriaQuestion,
} from './generators';

const questionTypes = [
  'singleAntibiotic',
  'multipleAntibiotics',
  // 'inputAntibiotic',
  'rankingAntibiotic',
  // 'descriptionBacteria',
  'singleBacteria',
  'multipleBacteria',
];
export const generateQuestion = data => {
  // Randomly select a question type
  const questionType =
    questionTypes[Math.floor(Math.random() * questionTypes.length)];

  // Generate a question based on the selected type
  switch (questionType) {
    case 'singleAntibiotic':
      return generateSingleAntibioticQuestion(data);
    case 'multipleAntibiotics':
      return generateMultipleAntibioticsQuestion(data);
    // case 'inputAntibiotic':
    //   return generateInputAntibioticQuestion(data);
    case 'rankingAntibiotic':
      return generateRankingAntibioticQuestion(data);
    // case 'descriptionBacteria':
    //   return generateDescriptionBacteriaQuestion();
    case 'singleBacteria':
      return generateSingleBacteriaQuestion(data);
    case 'multipleBacteria':
      return generateMultipleBacteriaQuestion(data);
    default:
      throw new Error(`Invalid question type: ${questionType}`);
  }
};

export const shuffle = array => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};
