import React, { ReactElement, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Quiz } from '../screens/Quiz/Quiz';
import { Landing } from '../screens/Landing';
import { PolishB1 } from '../screens/PolishB1';

export const AppNavigator = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);

  // If you have any loading logic, you can handle it here

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing/>} />
        <Route path="/quiz" element={<Quiz/>} />
        <Route path="/polishb1" element={<PolishB1/>} />
      </Routes>
    </Router>
  );
};