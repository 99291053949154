import React from 'react';
import { View, Text, Image, StyleSheet, ScrollView } from 'react-native';

export const PolishB1 = () => {
  return (
    <ScrollView style={styles.container}>
      <Header />
      <MainFeatures />
      <Testimonials />
    </ScrollView>
  );
};

const Header = () => {
  return (
    <View style={styles.header}>
      <Image source={require('../assets/polish.png')} style={styles.logo} />
      <Text style={styles.title}>Polish B1</Text>
      <Text>Learn Polish Quickly and Efficiently</Text>
    </View>
  );
};

const MainFeatures = () => {
  return (
    <View style={styles.features}>
      <Text style={styles.featureTitle}>The best educational service now in your pocket...</Text>
      {/* Additional feature descriptions and highlights */}
    </View>
  );
};

const Testimonials = () => {
  return (
    <View style={styles.testimonials}>
      <Text style={styles.testimonialTitle}>What our users say:</Text>
      <View style={styles.testimonialBox}>
        <Text>"I have been using Polish B1 for the past few weeks..."</Text>
        <Text style={styles.author}>- Mirha Fatima</Text>
      </View>
      <View style={styles.testimonialBox}>
        <Text>"I highly recommend Polish B1 to anyone looking to learn Polish quickly..."</Text>
        <Text style={styles.author}>- Patrick Hagenes</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f4f4f4'
  },
  header: {
    padding: 20,
    alignItems: 'center',
    backgroundColor: '#4C68D7',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc'
  },
  logo: {
    width: 120,
    height: 120,
    marginBottom: 20
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    color: '#fff'
  },
  features: {
    padding: 20
  },
  featureTitle: {
    fontSize: 20,
    marginBottom: 15,
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4
  },
  testimonials: {
    padding: 20
  },
  testimonialTitle: {
    fontSize: 24,
    marginBottom: 20
  },
  testimonialBox: {
    marginBottom: 20,
    backgroundColor: '#fff',
    padding: 15,
    borderRadius: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4
  },
  author: {
    fontSize: 16,
    fontStyle: 'italic',
    textAlign: 'right',
    marginTop: 10
  }
});
