import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FlatList, Image, StyleSheet, TouchableOpacity, View, Text } from 'react-native';
// import { SCREEN_WIDTH } from '../../util/dimensions';

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const SCREEN_WIDTH = 600;

export const ListComponent = ({ questions, activeQuestionIndex, onAnswer }) => {
  const listRef = useRef(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    setSelectedOptions([]);
    setHasSubmitted(false);
  }, [activeQuestionIndex]);

  const handleOptionPress = option => {
    if (questions[activeQuestionIndex].isMultipleAnswer) {
      setSelectedOptions(prev => {
        if (prev.includes(option)) {
          return prev.filter(o => o !== option);
        } else {
          return [...prev, option];
        }
      });
    } else {
      setSelectedOptions([option]);
      // handleSubmitPress();
    }
  };

  const handleSubmitPress = () => {
    setHasSubmitted(true);

    // Check if all selected options are correct and all correct options are selected
    const currentAnswer = questions?.[activeQuestionIndex]?.answer;

    if (!Array.isArray(selectedOptions)) {
      console.error('selectedOptions is not an array');
      return;
    }

    let isCorrect;

    if (typeof currentAnswer === 'string') {
      // if currentAnswer is a string, check if it's the only selected option
      isCorrect =
        selectedOptions.length === 1 && selectedOptions[0] === currentAnswer;
    } else if (Array.isArray(currentAnswer)) {
      // if currentAnswer is an array, check if all selected options are in currentAnswer and vice versa
      isCorrect =
        selectedOptions.every(option => currentAnswer.includes(option)) &&
        currentAnswer.every(ans => selectedOptions.includes(ans));
    } else {
      console.error('currentAnswer is neither a string nor an array');
      return;
    }

    setTimeout(() => {
      onAnswer(isCorrect);
      if (activeQuestionIndex < questions.length - 1) {
        listRef.current.scrollToIndex({
          animated: true,
          index: activeQuestionIndex + 1,
        });
      }
    }, 1000);
  };

  const keyExtractor = useCallback(item => item?.id, []);

  const getItemLayout = useCallback(
    (data, index) => ({
      length: SCREEN_WIDTH,
      offset: SCREEN_WIDTH * index,
      index,
    }),
    [],
  );

  const renderItem = useCallback(
    ({ item, index }) => {
      return (
        <View style={styles.container}>
          <View
            style={{
              flexGrow: 1,
              justifyContent: 'center',
              opacity: hasSubmitted ? 0.3 : 1,
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: 18,
                color: '#978A8A',
                marginBottom: 8,
              }}
            >
              Question #{index + 1}
            </Text>
            <Text style={{ fontSize: 22, fontWeight: 'bold' }}>
              {item?.question}
            </Text>
          </View>
          <View style={{}}>
            {item?.isMultipleAnswer ? (
              <Text style={{ color: '#978A8A', marginBottom: 5 }}>
                Multiple choice question
              </Text>
            ) : (
              <Text style={{ color: '#978A8A', marginBottom: 5 }}>
                Single choice question
              </Text>
            )}
            <View style={styles.optionColumn}>
              {item?.options?.map((option, index) => {
                return renderOptionButton(option, item, index);
              })}
            </View>

            <TouchableOpacity
              style={[
                styles.submitButton,
                selectedOptions.length !== 0 && !hasSubmitted
                  ? {}
                  : styles.disabled,
              ]}
              disabled={!(selectedOptions.length !== 0 && !hasSubmitted)}
              onPress={handleSubmitPress}
            >
              <Image
                style={{ width: 24, height: 24 }}
                source={require('../../assets/Arrow_Forward.png')}
              />
            </TouchableOpacity>
          </View>
        </View>
      );
    },
    [selectedOptions, hasSubmitted],
  );

  const renderOptionButton = (option, item, index) => {
    const isCorrect = item?.answer.includes(option);
    const isSelected = selectedOptions.includes(option);
    let buttonStyle = styles.option;
    let textStyle = styles.blackText;

    const formattedOption = capitalizeFirstLetter(option);

    if (isCorrect && hasSubmitted) {
      buttonStyle = [styles.option, styles.correct];
      textStyle = [styles.blackText, styles.correct];
    } else if (isSelected) {
      if (hasSubmitted) {
        buttonStyle = isCorrect
          ? [styles.option, styles.correct]
          : [styles.option, styles.incorrect];
        textStyle = isCorrect
          ? [styles.blackText, styles.correct]
          : [styles.blackText, styles.incorrect];
      } else {
        buttonStyle = [styles.option, styles.selected];
      }
    }

    const answeredIncorrect = isSelected && hasSubmitted && !isCorrect;
    const answeredCorrect = isSelected && hasSubmitted && isCorrect;
    const selectedButNotSubmitted = isSelected && !hasSubmitted;
    const selectedAndSubmitted = isSelected || (isSelected && hasSubmitted);

    const label = (() => {
      switch (index) {
        case 0:
          return 'A';
        case 1:
          return 'B';
        case 2:
          return 'C';
        case 3:
          return 'D';
        default:
          return 'A';
      }
    })();

    let labelBackgroundColor;

    if (isCorrect && hasSubmitted) {
      labelBackgroundColor = '#25DC6E';
    } else if (answeredIncorrect) {
      labelBackgroundColor = '#FF3A3A';
    } else {
      labelBackgroundColor = '#CBC6C6';
    }

    return (
      <TouchableOpacity
        key={index}
        style={[buttonStyle, selectedAndSubmitted ? { borderWidth: 1 } : {}]}
        onPress={() => handleOptionPress(option)}
        disabled={hasSubmitted}
      >
        <View style={{flexDirection: 'row', marginLeft: 19}}>
          <View
          style={{justifyContent: 'center', alignItems: 'center', borderRadius: 6, width: 25, height: 25, backgroundColor: labelBackgroundColor}}
          >
            <Text style={{color: 'white'}}>{label}</Text>
          </View>
          <Text style={[textStyle, {marginLeft: 16}]}>
            {formattedOption}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.mainContainer}>
      <FlatList
        ref={listRef}
        windowSize={5}
        initialNumToRender={5}
        maxToRenderPerBatch={5}
        horizontal={true}
        sliderWidth={SCREEN_WIDTH}
        itemWidth={SCREEN_WIDTH}
        pagingEnabled
        data={questions}
        scrollEnabled={true}
        initialScrollIndex={activeQuestionIndex}
        getItemLayout={getItemLayout}
        decelerationRate="fast"
        bounces={true}
        inactiveSlideOpacity={1}
        inactiveSlideScale={1}
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps={'always'}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    width: SCREEN_WIDTH,
    flex: 1,
  },
  container: {
    width: SCREEN_WIDTH,
    paddingHorizontal: 24,
  },
  option: {
    backgroundColor: '#F1EEEE',
    marginVertical: 5,
    borderRadius: 20,
    width: SCREEN_WIDTH - 48,
    height: 64,
    textAlign: 'center',
    justifyContent: 'center',
  },
  optionRow: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  correct: {
    backgroundColor: '#CFFFE2',
  },
  incorrect: {
    backgroundColor: '#FFCFCF',
  },
  selected: {},
  whiteText: {
    color: 'white',
    textAlign: 'center',
  },
  blackText: {
    color: 'black',
    fontWeight: '600',
    fontSize: 18,
  },
  disabled: {
    opacity: 0.3,
  },
  submitButton: {
    borderWidth: 1,
    marginVertical: 10,
    padding: 10,
    borderRadius: 50,
    marginHorizontal: 18,
    width: 74,
    height: 74,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    alignSelf: 'center',
  },
  submitButtonText: {
    textAlign: 'center',
  },
  optionColumn: {
    flexDirection: 'column',
  },
});
