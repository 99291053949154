import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore/lite';
import 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useQuizStore, useUserStore } from '../../store/store';
import { ListComponent } from './ListComponent';
import { generateQuestion } from './helpers';
import { ProgressBar } from './progressBar';

export const questionsCount = 30;

const firebaseConfig = {
  apiKey: "AIzaSyBWXVHA1um8bsbdQfdzmG3tj4szlD8hpzU",
  authDomain: "antibacti.firebaseapp.com",
  projectId: "antibacti",
  storageBucket: "antibacti.appspot.com",
  messagingSenderId: "897718931621",
  appId: "1:897718931621:web:e283a5e49c9bb498edfd4c",
  measurementId: "G-R3Y14F29VD"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const firestore = getFirestore(firebase);


export const Quiz = () => {
  const { addAnswer, clearAnswers, finished, setFinished } = useQuizStore();
  const { subscribed, setSubscribed } = useUserStore();
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [antibiotics, setAntibiotics] = useState([]);
  const [bacteria, setBacteria] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const data = { bacteria, antibiotics, interactions };

  useEffect(() => {
    // setSubscribed(false);
    setIsLoading(true);
    setQuestions([]);
    setActiveQuestionIndex(0);
    clearAnswers();
    const fetchFirebaseData = async () => {
      // Reference to the 'Antibacti' collection
      const antibactiRef = collection(firestore, 'Antibacti');

      // Fetch Antibiotics Data
      const antibioticsDoc = await getDoc(doc(firestore, "Antibacti", "Antibiotics"));
      // const antibioticsDoc = await antibactiRef.doc('Antibiotics').get();
      const antibioticsDataRaw = antibioticsDoc.data() || {};
      const antibioticsData = Object.keys(antibioticsDataRaw).map(id => ({
        id,
        ...antibioticsDataRaw[id],
      }));
      setAntibiotics(antibioticsData);

      // Fetch Bacteria Data
      // const bacteriaDoc = await antibactiRef.doc('Bacteria').get();
      const bacteriaDoc = await getDoc(doc(firestore, "Antibacti", "Bacteria"));
      const bacteriaDataRaw = bacteriaDoc.data() || {};
      let bacteriaData = Object.keys(bacteriaDataRaw).map(id => ({
        id,
        ...bacteriaDataRaw[id],
      }));
      // Filter based on type if not subscribed
      if (!subscribed) {
        bacteriaData = bacteriaData.filter(bacteria =>
          ['Aerobic G+', 'Anaerobic'].includes(bacteria.type),
        );
      }
      setBacteria(bacteriaData);

      // Fetch Interactions Data
      // const interactionsDoc = await antibactiRef.doc('Interactions').get();
      const interactionsDoc = await getDoc(doc(firestore, "Antibacti", "Interactions"));
      const interactionsDataRaw = interactionsDoc.data() || {};
      const interactionsData = Object.keys(interactionsDataRaw).map(id => ({
        id,
        ...interactionsDataRaw[id],
      }));
      const filteredInteractions = interactionsData.filter(interaction => {
        // Check if this interaction's bacteria ID is present in the included bacteria list
        return bacteriaData.some(
          bacteria => bacteria.id === interaction.bacteria,
        );
      });
      setInteractions(filteredInteractions);

      setIsLoading(false);
      setFinished(false);
    };

    fetchFirebaseData();
  }, [subscribed, finished]);

  useEffect(() => {
    if (!isLoading) {
      const generatedQuestions = [];
      for (let i = 0; i < questionsCount; i++) {
        generatedQuestions.push(generateQuestion(data));
      }
      setQuestions(generatedQuestions);
    }
  }, [antibiotics, bacteria, interactions, isLoading]);

  const handleNextQuestion = isCorrect => {
    addAnswer({
      question: questions[activeQuestionIndex].question,
      isCorrect,
      bacteriaType: questions[activeQuestionIndex].bacteriaType,
      antibioticType: questions[activeQuestionIndex].antibioticType,
    });

    if (activeQuestionIndex < questions.length - 1) {
      setActiveQuestionIndex(prevIndex => prevIndex + 1);
    } else {
      // navigation.navigate('Result', { isCorrect });
    }
  };

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <View style={[styles.container, { paddingTop: 15 }]}>
      <ProgressBar questionsCount={30} />
      {questions.length > 0 ? (
        <>
          <ListComponent
            questions={questions}
            activeQuestionIndex={activeQuestionIndex}
            onAnswer={handleNextQuestion}
          />
        </>
      ) : (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
});
