import React from 'react';
import { View, Text, Image, Button, StyleSheet, Pressable } from 'react-native';
import {SCREEN_HEIGHT} from '../util/dimensions';
import { useNavigate } from "react-router-dom";

export const Landing = () => {
  const navigate = useNavigate();
  return (
    <View style={styles.container}>

      {/* Main Banner */}
      <View style={styles.banner}>
        <Text style={styles.bannerTitle}>IDEX Studios</Text>
        <Text style={styles.bannerSubtitle}>Crafting Innovative Digital Experiences</Text>
      </View>

      {/* Apps Showcase */}
      <View style={styles.appsContainer}>
        {/* Repeat this block for each app */}
        <Pressable onPress={() => {navigate('/quiz')}} style={[styles.app, {backgroundColor: '#6B9BBF'}]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Image source={require('../assets/antibacti.png')} style={styles.appIconAntibacti} />
            <Text style={[styles.appName, {color: 'white', width: 280}]}>Antibiotics & Bacteria Quizzes</Text>
          </View>
          <Pressable onPress={() => {navigate('/quiz')}} style={styles.btn} title="Learn More">
            <Text style={styles.btnText}>Learn more</Text>
          </Pressable>
        </Pressable>
        <Pressable onPress={() => {navigate('/polishb1')}} style={[styles.app, {backgroundColor: 'white'}]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Image source={require('../assets/polish.png')} style={styles.appIconPolish} />
            <View style={{flexDirection: 'column', padding: 15}}>
              <Text style={styles.appName}>Polish B1</Text>
              <Text style={{fontSize: 18}}>Learn Polish with free daily lessons</Text>
            </View>
          </View>
          <Pressable onPress={() => {navigate('/polishb1')}} style={[styles.btn, {backgroundColor: '#E30613', marginTop: 25}]} title="Learn More">
            <Text style={[styles.btnText, {color: 'white'}]}>Learn more</Text>
          </Pressable>
        </Pressable>
      </View>

      {/* About Section */}
      {/* <View style={styles.aboutContainer}>
        <Image source={{ uri: 'path_to_about_image' }} style={styles.aboutImage} />
        <Text style={styles.aboutText}>About IDEX Studios...</Text>
      </View> */}

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    height: SCREEN_HEIGHT,
    backgroundColor: '#F5F5F5',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 100,
    height: 40,
    resizeMode: 'contain',
  },
  navLinks: {
    fontSize: 16,
  },
  banner: {
    marginTop: 20,
    padding: 20,
    alignSelf: 'center',
  },
  bannerTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  bannerSubtitle: {
    fontSize: 18,
    textAlign: 'center',
  },
  appsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  app: {
    width: 600,
    height: 400,
    alignItems: 'center',
    backgroundColor: 'orange',
    borderRadius: 25,
    justifyContent: 'center',
    marginRight: 30,
    padding: 15,
    paddingBottom: 50,
  },
  appIconAntibacti: {
    width: 200,
    height: 200,
    borderRadius: 25,
  },
  appIconPolish: {
    width: 165,
    height: 165,
    borderRadius: 25,
  },
  appName: {
    fontSize: 26,
    fontWeight: 'bold',
  },
  aboutContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  aboutImage: {
    width: '45%',
    resizeMode: 'cover',
  },
  aboutText: {
    width: '45%',
    fontSize: 16,
  },
  footer: {
    marginTop: 20,
    padding: 10,
    backgroundColor: '#333',
  },
  footerText: {
    color: 'white',
    fontSize: 16,
  },
  btn: {
    position: 'absolute',
    bottom: 40,
    backgroundColor: 'white',
    width: 150,
    height: 50,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnText: {
    fontSize: 18,
    fontWeight: 'bold',
  }
});
