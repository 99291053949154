import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useQuizStore } from '../../store/store';

export const ProgressBar = ({ questionsCount }) => {
  const { answers } = useQuizStore();
  return (
    <View style={styles.progressBar}>
      {Array.from({ length: questionsCount }, (_, index) => {
        const segmentColor = answers?.[index]?.isCorrect
          ? '#25DC6E'
          : answers[index] != null
          ? '#FF3A3A'
          : '#CBC6C6';

        return (
          <View
            style={[styles.progressSegment, { backgroundColor: segmentColor }]}
            key={index}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  progressBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 24,
    marginTop: 30,
  },
  progressSegment: {
    height: 6,
    width: 6,
    borderRadius: 6,
    backgroundColor: 'red',
  },
});
