import { create } from 'zustand';
import { persist, createJSONStorage, StateStorage } from 'zustand/middleware';

export const useQuizStore = create(
  persist(
    set => ({
      finished: true,
      answers: [],
      addAnswer: answer =>
        set(state => ({ answers: [...state.answers, answer] })),
      clearAnswers: () => set({ answers: [] }),
      setFinished: value => set(state => ({ ...state, finished: value })),
      _hasHydrated: false,
      setHasHydrated: state => {
        set({
          _hasHydrated: state,
        });
      },
    }),
    {
      name: 'quiz-storage',
    },
  ),
);

export const useUserStore = create(
  persist(
    set => ({
      onboarded: false,
      subscribed: false,
      setOnboarded: value => set(state => ({ onboarded: true })),
      setSubscribed: value => set(state => ({ subscribed: value })),
      _hasHydrated: false,
      setHasHydrated: state => {
        set({
          _hasHydrated: state,
        });
      },
    }),
    {
      name: 'user-storage',
    },
  ),
);
