import { shuffle } from './helpers';
import uuid from 'react-native-uuid';

export const generateSingleAntibioticQuestion = ({
  bacteria,
  antibiotics,
  interactions,
}) => {
  // Randomly select a bacteria
  const selectedBacteria =
    bacteria[Math.floor(Math.random() * bacteria.length)];

  // Find interactions for the selected bacteria where rating is not 0
  const interactionsForBacteria = interactions.filter(
    interaction =>
      interaction?.bacteria === selectedBacteria?.id &&
      interaction?.rating !== 0,
  );

  // Randomly select one of these interactions to be the correct answer
  const correctInteraction =
    interactionsForBacteria[
      Math.floor(Math.random() * interactionsForBacteria.length)
    ];

  // Find the antibiotic for the correct interaction
  const correctAntibiotic = antibiotics.find(
    antibiotic => antibiotic.id === correctInteraction?.antibiotic,
  );

  // Find interactions for the selected bacteria where rating is 0 for wrong answers
  const wrongInteractions = interactions
    .filter(
      interaction =>
        interaction?.bacteria === selectedBacteria?.id &&
        interaction?.rating === '0' &&
        interaction?.antibiotic !== correctInteraction?.antibiotic, // Exclude the correct antibiotic
    )
    .slice(0, 3); // Take first three

  // Find the antibiotics for the wrong interactions
  const wrongAntibiotics = wrongInteractions.map(interaction =>
    antibiotics.find(antibiotic => antibiotic?.id === interaction?.antibiotic),
  );

  // Randomize the order of the answers
  const answers = shuffle([
    correctAntibiotic?.name,
    ...wrongAntibiotics.map(a => a?.name),
  ]);

  // Construct the question object
  const question = {
    question: `When dealing with an infection caused by ${selectedBacteria?.name}, which antibiotic would be appropriate?`,
    id: uuid.v4(), // Ensure the ID is unique
    options: answers,
    answer: correctAntibiotic?.name,
    isMultipleAnswer: false,
    bacteriaType: selectedBacteria?.type,
    antibioticType: correctAntibiotic?.type,
  };

  return question;
};

export const generateMultipleAntibioticsQuestion = ({
  bacteria,
  antibiotics,
  interactions,
}) => {
  const selectedBacteria =
    bacteria[Math.floor(Math.random() * bacteria.length)];

  const interactionsForBacteria = interactions.filter(
    interaction =>
      interaction?.bacteria === selectedBacteria?.id &&
      interaction?.rating !== '0',
  );

  // Ensure we have at least 1 and at most 3 correct answers
  const numberOfCorrectAnswers = 1 + Math.floor(Math.random() * 3);

  const correctInteractions = shuffle(interactionsForBacteria).slice(
    0,
    numberOfCorrectAnswers,
  );

  const correctAntibiotics = correctInteractions.map(interaction =>
    antibiotics.find(antibiotic => antibiotic.id === interaction?.antibiotic),
  );

  const wrongInteractions = interactions.filter(
    interaction =>
      interaction?.bacteria === selectedBacteria?.id &&
      interaction?.rating === '0',
  );

  // Make sure the total number of answers is exactly 4
  const numberOfIncorrectAnswers = 4 - correctAntibiotics.length;

  const incorrectInteractions = shuffle(wrongInteractions).slice(
    0,
    numberOfIncorrectAnswers,
  );

  const incorrectAntibiotics = incorrectInteractions.map(interaction =>
    antibiotics.find(antibiotic => antibiotic.id === interaction?.antibiotic),
  );

  const answers = shuffle(
    [...correctAntibiotics, ...incorrectAntibiotics].map(a => a?.name),
  );

  const question = {
    question: `In case of ${selectedBacteria?.name} infection, which antibiotics could potentially be used?`,
    id: uuid.v4(),
    options: answers,
    answer: correctAntibiotics.map(a => a?.name),
    isMultipleAnswer: true,
    bacteriaType: selectedBacteria?.type,
    antibioticType: '',
  };

  return question;
};

export const generateRankingAntibioticQuestion = ({
  bacteria,
  antibiotics,
  interactions,
}) => {
  let question = null;

  // Make sure there are enough antibiotics and interactions
  if (bacteria.length && antibiotics.length && interactions.length >= 4) {
    let selectedBacteria,
      selectedRating,
      correctInteraction,
      correctAntibiotic,
      wrongInteractions,
      wrongAntibiotics;

    do {
      // Randomly select a bacteria
      selectedBacteria = bacteria[Math.floor(Math.random() * bacteria.length)];

      // Select a random rating (1, 2, or 3)
      selectedRating = Math.ceil(Math.random() * 3);

      // Find the interaction for the selected bacteria with the selected rating
      correctInteraction = interactions.find(
        interaction =>
          interaction.bacteria === selectedBacteria.id &&
          interaction.rating === selectedRating.toString(),
      );
    } while (!correctInteraction); // If no interaction found, re-select bacteria and rating

    // Find the antibiotic for the correct interaction
    correctAntibiotic = antibiotics.find(
      antibiotic => antibiotic.id === correctInteraction?.antibiotic,
    );

    do {
      // Find 3 interactions for the selected bacteria where rating is '0' (for wrong answers)
      wrongInteractions = interactions
        .filter(
          interaction =>
            interaction.bacteria === selectedBacteria.id &&
            interaction.rating === '0',
        )
        .slice(0, 3);

      // Find the antibiotics for the wrong interactions
      wrongAntibiotics = wrongInteractions.map(interaction =>
        antibiotics.find(
          antibiotic => antibiotic.id === interaction?.antibiotic,
        ),
      );
    } while (wrongAntibiotics.length < 3); // If not enough wrong answers found, re-select

    // Randomize the order of the answers
    const answers = shuffle(
      [correctAntibiotic, ...wrongAntibiotics].map(a => a?.name),
    );

    // Construct the question object
    question = {
      question:
        selectedRating === 1
          ? 'For community-acquired ' +
            `${selectedBacteria.name} infections, the primary treatment is which antibiotic?`
          : 'For healthcare-acquired ' +
            `${selectedBacteria.name} infections, what can be used?`,
      id: uuid.v4(), // Ensure the ID is unique
      options: answers,
      answer: [correctAntibiotic?.name], // The correct answer is the antibiotic with the correct ranking
      isMultipleAnswer: false,
      bacteriaType: selectedBacteria?.type,
      antibioticType: correctAntibiotic?.type,
    };
  }

  return question;
};

export const generateSingleBacteriaQuestion = ({
  bacteria,
  antibiotics,
  interactions,
}) => {
  // Find antibiotics that have suitable interactions
  const antibioticsWithInteractions = antibiotics.filter(antibiotic =>
    interactions.some(
      interaction =>
        interaction?.antibiotic === antibiotic?.id &&
        interaction?.rating !== '0',
    ),
  );

  if (!antibioticsWithInteractions.length) {
    console.log('No antibiotics with suitable interactions found');
    return null;
  }

  // Randomly select an antibiotic from the ones that have suitable interactions
  const selectedAntibiotic =
    antibioticsWithInteractions[
      Math.floor(Math.random() * antibioticsWithInteractions.length)
    ];

  // Find interactions for the selected antibiotic where rating is not '0'
  const interactionsForAntibiotic = interactions.filter(
    interaction =>
      interaction?.antibiotic === selectedAntibiotic?.id &&
      interaction?.rating !== '0',
  );

  let selectedCorrectInteraction;
  let correctBacteria;

  // Try up to a maximum number of times (to prevent infinite loops in case of bad data)
  const maxAttempts = interactionsForAntibiotic.length;

  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    // Randomly select an interaction
    selectedCorrectInteraction =
      interactionsForAntibiotic[
        Math.floor(Math.random() * interactionsForAntibiotic.length)
      ];

    // Find the bacteria for the selected interaction
    correctBacteria = bacteria.find(
      bact => bact.id === selectedCorrectInteraction?.bacteria,
    );

    // If a valid bacteria was found, break out of the loop
    if (correctBacteria) {
      break;
    }
  }

  // If after all attempts, no valid bacteria is found, log an error or handle accordingly
  if (!correctBacteria) {
    console.error('Could not find a bacteria for any selected interaction.');
    return null;
  }

  // Find interactions for the selected antibiotic where rating is '0' (for wrong answers)
  const wrongInteractions = interactions.filter(
    interaction =>
      interaction?.antibiotic === selectedAntibiotic?.id &&
      interaction?.rating === '0',
  );

  // Find the bacteria for the wrong interactions
  const incorrectBacteria = shuffle(
    wrongInteractions.map(interaction =>
      bacteria.find(bact => bact.id === interaction?.bacteria),
    ),
  ).filter(Boolean); // Filter out undefined values and shuffle them

  // Determine how many incorrect answers to include, ensuring the total number of answers is 4
  const numberOfIncorrectAnswers = 3; // 3 incorrect answers and 1 correct one

  // Randomly select a subset of the incorrect bacteria for the incorrect answers
  const selectedIncorrectBacteria = incorrectBacteria.slice(
    0,
    numberOfIncorrectAnswers,
  );

  // Combine correct and wrong bacteria and shuffle them to get a randomized order
  const options = shuffle(
    [correctBacteria, ...selectedIncorrectBacteria].filter(Boolean),
  ).map(b => b.name);

  // Construct the question object
  const question = {
    question: `What bacteria are typically targeted by ${selectedAntibiotic.name}?`,
    id: uuid.v4(), // Ensure the ID is unique
    options: options,
    answer: [correctBacteria.name], // The correct answer is the bacteria with the correct rating
    isMultipleAnswer: false,
    bacteriaType: '',
    antibioticType: selectedAntibiotic.type,
  };

  return question;
};

export const generateMultipleBacteriaQuestion = ({
  bacteria,
  antibiotics,
  interactions,
}) => {
  // Find antibiotics that have suitable interactions
  const antibioticsWithInteractions = antibiotics.filter(antibiotic =>
    interactions.some(
      interaction =>
        interaction?.antibiotic === antibiotic?.id &&
        interaction?.rating !== '0',
    ),
  );

  if (!antibioticsWithInteractions.length) {
    console.log('No antibiotics with suitable interactions found');
    return null;
  }

  // Randomly select an antibiotic from the ones that have suitable interactions
  const selectedAntibiotic =
    antibioticsWithInteractions[
      Math.floor(Math.random() * antibioticsWithInteractions.length)
    ];

  // Find interactions for the selected antibiotic where rating is not '0'
  const interactionsForAntibiotic = interactions.filter(
    interaction =>
      interaction?.antibiotic === selectedAntibiotic?.id &&
      interaction?.rating !== '0',
  );

  // Determine how many correct answers to include (up to 4, but minimum 1)
  const numberOfCorrectAnswers = Math.max(
    1,
    Math.min(interactionsForAntibiotic.length, Math.ceil(Math.random() * 3)),
  );

  // Randomly select a subset of these interactions to be the correct answers
  const correctInteractions = shuffle(interactionsForAntibiotic).slice(
    0,
    numberOfCorrectAnswers,
  );

  // Find the bacteria for the correct interactions
  const correctBacteria = correctInteractions
    .map(interaction =>
      bacteria.find(bact => bact.id === interaction?.bacteria),
    )
    .filter(Boolean);

  // Find interactions for the selected antibiotic where rating is '0' (for wrong answers)
  const wrongInteractions = interactions.filter(
    interaction =>
      interaction?.antibiotic === selectedAntibiotic?.id &&
      interaction?.rating === '0',
  );

  // Find the bacteria for the wrong interactions
  const incorrectBacteria = shuffle(
    wrongInteractions.map(interaction =>
      bacteria.find(bact => bact.id === interaction?.bacteria),
    ),
  ).filter(Boolean); // Filter out undefined values and shuffle them

  // Determine how many incorrect answers to include, ensuring the total number of answers is up to 4
  const numberOfIncorrectAnswers = Math.max(0, 4 - correctBacteria.length);

  // Randomly select a subset of the incorrect bacteria for the incorrect answers
  const selectedIncorrectBacteria = incorrectBacteria.slice(
    0,
    numberOfIncorrectAnswers,
  );

  // Combine correct and wrong bacteria and shuffle them to get a randomized order
  const options = shuffle(
    [...correctBacteria, ...selectedIncorrectBacteria].filter(Boolean),
  ).map(b => b.name);

  // Construct the question object
  const question = {
    question: `What bacteria are typically targeted by ${selectedAntibiotic.name}?`,
    id: uuid.v4(), // Ensure the ID is unique
    options: options,
    answer: correctBacteria.map(b => b.name), // The correct answer is the bacteria with the correct rating
    isMultipleAnswer: true,
    bacteriaType: '',
    antibioticType: selectedAntibiotic.type,
  };

  return question;
};
